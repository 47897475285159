import Button from 'components/Button'
import InputContainer from 'components/Inputs/Container'
import Text from 'components/Inputs/Text'
import PreviewEmailModal from 'components/Modals/PreviewEmailModal'
import Toggle from 'components/Toggle'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SettingsModule from '../SettingsModule'
import { FormInputs } from './FormInput'
import { ConfirmationEmailSettings } from './types'

interface EmailSettingsProps {
  confirmationEmail: ConfirmationEmailSettings
  guestlistId?: string | null
  hasUnsavedChanges?: boolean
  updateEmailSettings: (val: Partial<ConfirmationEmailSettings>) => void
}

export const EmailSettings: React.FC<EmailSettingsProps> = ({
  confirmationEmail,
  guestlistId,
  hasUnsavedChanges,
  updateEmailSettings
}) => {
  const [previewModalOpen, setPreviewModalOpen] = useState(false)
  const { t } = useTranslation()

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target
    updateEmailSettings({
      ...confirmationEmail,
      [name]: checked
    })
  }

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target
    updateEmailSettings({
      ...confirmationEmail,
      [name]: value
    })
  }

  const {
    header = '',
    content = '',
    enabled = false,
    event_information = false,
    subject = ''
  } = confirmationEmail

  return (
    <>
      <SettingsModule
        title={t('confirmation email')}
        className="EmailSettings"
        rightContent={
          <>
            {enabled && (
              <Button
                type="secondary"
                disabled={hasUnsavedChanges}
                onClick={() => setPreviewModalOpen(true)}>
                {t('preview')}
              </Button>
            )}
            <div className="SettingsModuleSpacer">
              <Toggle
                size="large"
                id="confirmation-email-enabled"
                name="enabled"
                handleChange={handleToggleChange}
                disabled={!enabled}
                label={undefined}
              />
            </div>
          </>
        }
        description={undefined}
        size={undefined}>
        {enabled && (
          <div className="EmailContent">
            <InputContainer id="emailSubject" label={t('subject')}>
              <Text
                value={subject}
                handleChange={handleInputChange}
                placeholder={t('subject')}
                name="subject"
              />
            </InputContainer>
            <FormInputs
              header="emailTitle"
              headerContent={header}
              body="emailContent"
              bodyContent={content}
              handleInputChange={handleInputChange}
            />
            <div className="EventInformationToggle">
              <Toggle
                size="large"
                id="event_information"
                name="event_information"
                handleChange={handleToggleChange}
                disabled={!event_information}
                label={undefined}
              />
              <b>{t('event information')}</b> ({t('date, time and location')})
            </div>
          </div>
        )}
      </SettingsModule>

      {previewModalOpen && (
        <PreviewEmailModal
          handleClose={() => setPreviewModalOpen(false)}
          guestlistId={guestlistId || null}
        />
      )}
    </>
  )
}
