import React, {
  useEffect,
  useReducer,
  createContext,
  FC,
  ReactNode,
  ChangeEvent
} from 'react'
import { omit } from 'lodash'
import moment from 'moment-timezone'

import {
  reducer,
  formFactorys,
  getFormEvent,
  hasFormBeenUpdated,
  CurrentState
} from 'components/Contexts/UndoProvider/helpers'

export interface ExtendedLandingPages {
  hasPageChanges?: boolean
  resetPageChanges?: () => void
  savePage?: () => Promise<any>
  isLoading?: boolean
}

export interface EventData {
  // Define your event structure here based on your data shape
  event?: string | null
  last_modified?: string | null
  guestlists?: Array<{
    key: number
    end_time: string
    event?: string
    guest_max_limit?: number | null
    organization?: string
    start_time: string
    name?: string
  }>
  [key: string]: any
}

export interface UndoContextState {
  history: any[]
  index: number
  currentState: CurrentState
}

interface UndoContextValue extends UndoContextState {
  hasUnsavedChanges: boolean
  isLoading: boolean
  setDefaultNumberOfFollowers: () => void
  canSave: () => boolean
  compareHistoryStates: () => boolean
  updateHistory: () => void
  undo: () => void
  redo: () => void
  handleInputBlur: () => boolean | void
  handleChange: (page: string) => (e: ChangeEvent<HTMLInputElement>) => void
  handleBackgroundChange: (params: {
    page: string
    value: string
    fieldName: string
  }) => void
  handleFactoryFieldChange: (payload: any) => void
  handleFactoryFieldRequiredChange: (payload: any) => void
  handleFactoryFieldTextareaChange: (payload: any) => void
  handleCreateField: (fieldData: any, factoryId: any) => void
  handleFactoryFieldDelete: (payload: any) => void
  handleGuestCountChange: (nrOfFollowers: string) => void
  handleDragEnd: (params: { destination: any; source: any }) => void
  handleResetChanges: () => void
  handleSaveEvent: () => Promise<any> | undefined
  updateCampaignId: (campaignId: any) => void
  updateEventName: (name: any) => void
  updateEventLocation: (location: any) => void
  updateGuestMaxLimit: (guestMaxLimit: any) => void
  updateStartTime: (timestamp: string) => void
  updateEndTime: (timestamp: string) => void
  updateEmailSettings: (value: any) => void
  updateCalendarSettings: (value: any) => void
  updateTimeZoneLocation: (value: string) => void
  addTimeSlot: () => void
  removeTimeSlot: (key: number) => void
  updateTimeSlot: (data: any) => void
  updateLegalText: (value: string) => void
}

export const UndoContext = createContext<UndoContextValue | undefined>(
  undefined
)

interface UndoProviderProps {
  event?: EventData
  updateEvent: (data: any) => Promise<any>
  isEventLoading?: boolean
  extendedLandingPages?: ExtendedLandingPages
  children: ReactNode
}

const onlyNumbersRegex = /^(\s*|\d+)$/

const UndoProvider: FC<UndoProviderProps> = ({
  event,
  updateEvent,
  isEventLoading = false,
  extendedLandingPages,
  children
}) => {
  const [state, dispatch] = useReducer(reducer, {
    history: [],
    index: 0,
    currentState: {
      max_number_of_followers: 0,
      data: {},
      formFactorys
    }
  })

  const handleCreateField = (fieldData: any, factoryId: any) => {
    dispatch({ type: 'FieldCreate', payload: { fieldData, factoryId } })
  }

  const handleFactoryFieldDelete = (payload: any) =>
    dispatch({ type: 'FieldDelete', payload })

  const handleFactoryFieldChange = (payload: any) =>
    dispatch({ type: 'FieldChange', payload })

  const handleFactoryFieldRequiredChange = (payload: any) =>
    dispatch({ type: 'FieldRequiredChange', payload })

  const handleFactoryFieldTextareaChange = (payload: any) =>
    dispatch({ type: 'FieldTextAreaChange', payload })

  const handleChange = (page: string) => ({
    target
  }: ChangeEvent<HTMLInputElement>) => {
    const { value, id: fieldName } = target
    dispatch({
      type: 'DescriptionFieldChange',
      payload: { page, value, fieldName }
    })
  }

  const handleBackgroundChange = ({
    page,
    value,
    fieldName
  }: {
    page: string
    value: string
    fieldName: string
  }) => {
    dispatch({
      type: 'MultiplePageChange',
      payload: { page, value, fieldName }
    })
  }

  const handleDragEnd = ({
    destination,
    source
  }: {
    destination: any
    source: any
  }) => {
    ;(document.activeElement as HTMLElement)?.blur()

    if (destination) {
      if (destination !== source) {
        dispatch({ type: 'DragEndChange', payload: { destination, source } })
      }
    }
  }

  const handleInputBlur = () =>
    compareHistoryStates() ? false : updateHistory()

  const handleGuestCountChange = (nrOfFollowers: string) => {
    if (onlyNumbersRegex.test(nrOfFollowers)) {
      const maxNrOfFollowers = Number(nrOfFollowers)
      dispatch({ type: 'GuestCountChange', payload: { maxNrOfFollowers } })
    }
  }

  const createInitialHistory = (eventData: EventData) => {
    dispatch({ type: 'CreateInitialHistory', payload: { event: eventData } })
  }

  const updateHistory = () => {
    dispatch({ type: 'UpdateHistory' })
  }

  const moveHistoryPointer = (relativeIndex: number) => {
    dispatch({ type: 'MoveHistoryPointer', payload: { relativeIndex } })
  }

  const undo = () => moveHistoryPointer(-1)

  const redo = () => moveHistoryPointer(1)

  const setDefaultNumberOfFollowers = () => {
    dispatch({ type: 'SetDefaultNrOfFollowers' })
  }

  const compareHistoryStates = () => {
    const { currentState, history, index } = state
    return JSON.stringify(currentState) === JSON.stringify(history[index])
  }

  const updateEventStateAttribute = (attribute: string, value: any) => {
    dispatch({
      type: 'UpdateEventStateAttribute',
      payload: { attribute, value }
    })
  }

  const updateEventName = (name: any) => {
    updateEventStateAttribute('name', name)
  }

  const updateCampaignId = (campaignId: any) => {
    updateEventDataAttribute('campaign_id', campaignId)
  }

  const updateEventLocation = (location: any) => {
    updateEventStateAttribute('location', location)
    updateEventDataAttribute('location', location)
  }

  const updateGuestMaxLimit = (guestMaxLimit: any) => {
    updateEventStateAttribute('guest_max_limit', guestMaxLimit)
  }

  const updateStartTime = (timestamp: string) => {
    updateEventStateAttribute('start_time', timestamp)
  }

  const updateEndTime = (timestamp: string) => {
    updateEventStateAttribute('end_time', timestamp)
  }

  const addTimeSlot = () => {
    const { guestlists = [] } = state.currentState

    const prevTimeSlot =
      guestlists.length > 0 ? guestlists[guestlists.length - 1] : null

    updateEventStateAttribute('guestlists', [
      ...guestlists,
      {
        end_time: prevTimeSlot
          ? prevTimeSlot.end_time
          : state.currentState.end_time,
        event: state.currentState.event,
        guest_max_limit: prevTimeSlot ? prevTimeSlot.guest_max_limit : null,
        organization: state.currentState.organization,
        start_time: prevTimeSlot
          ? prevTimeSlot.start_time
          : state.currentState.start_time,
        name: prevTimeSlot ? prevTimeSlot.name : '',
        key: prevTimeSlot ? prevTimeSlot.key + 1 : 0
      }
    ])
  }

  const removeTimeSlot = (key: number) => {
    updateEventStateAttribute(
      'guestlists',
      state.currentState.guestlists?.filter(guestlist => guestlist.key !== key)
    )
  }

  const updateTimeSlot = (data: any) => {
    updateEventStateAttribute('guestlists', data)
  }

  const updateEventDataAttribute = (attribute: string, value: any) => {
    dispatch({
      type: 'UpdateEventDataAttribute',
      payload: { attribute, value }
    })
  }

  const updateEmailSettings = (value: any) => {
    updateEventDataAttribute('confirmation_email', value)
    if (!value.enabled) {
      updateCalendarSettings({
        ...state.currentState.data.calendar_reminder,
        enabled: false
      })
    }
  }

  const updateCalendarSettings = (value: any) => {
    updateEventDataAttribute('calendar_reminder', value)
  }

  const updateTimeZoneLocation = (value: string) => {
    updateEventDataAttribute('timezone_location', value)
    updateEventStateAttribute(
      'guestlists',
      state.currentState.guestlists?.map(guestlist => ({
        ...guestlist,
        start_time: moment.tz(guestlist.start_time, value).format(),
        end_time: moment.tz(guestlist.end_time, value).format()
      }))
    )
  }

  const updateLegalText = (value: string) => {
    updateEventDataAttribute('legal_text', value)
  }

  const setCurrentState = (eventData: EventData) => {
    dispatch({ type: 'SetCurrentState', payload: { event: eventData } })
  }

  const eventId = event ? event.event : null
  const lastModified = event ? event.last_modified : null

  useEffect(() => {
    event && createInitialHistory(event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  useEffect(() => {
    if (!state.currentState.last_modified || !lastModified || !event) {
      return
    }

    setCurrentState(event)
  }, [lastModified]) // eslint-disable-line react-hooks/exhaustive-deps

  const canSave = () =>
    state.currentState.formFactorys.every(
      factory =>
        (state.currentState.max_number_of_followers === 0 &&
          factory.optional) ||
        factory.fields.some((field: any) => field.required)
    )

  const saveKeyBlacklist = ['location']

  const handleResetChanges = () => {
    if (extendedLandingPages?.hasPageChanges) {
      extendedLandingPages.resetPageChanges?.()
    } else {
      event && createInitialHistory(event)
    }
  }

  const handleSaveEvent = () => {
    if (extendedLandingPages?.hasPageChanges) {
      return extendedLandingPages.savePage?.()
    } else {
      saveKeyBlacklist.forEach(key => updateEventStateAttribute(key, null))

      const newState = omit(state.currentState, saveKeyBlacklist)

      return updateEvent(getFormEvent((newState as unknown) as CurrentState))
    }
  }

  const hasUnsavedChanges =
    (extendedLandingPages?.hasPageChanges ?? false) ||
    hasFormBeenUpdated(event, state.currentState)

  const isLoading = isEventLoading || (extendedLandingPages?.isLoading ?? false)

  const value: UndoContextValue = {
    ...state,
    hasUnsavedChanges,
    isLoading,
    setDefaultNumberOfFollowers,
    canSave,
    compareHistoryStates,
    updateHistory,
    undo,
    redo,
    handleInputBlur,
    handleChange,
    handleBackgroundChange,
    handleFactoryFieldChange,
    handleFactoryFieldRequiredChange,
    handleFactoryFieldTextareaChange,
    handleCreateField,
    handleFactoryFieldDelete,
    handleGuestCountChange,
    handleDragEnd,
    handleResetChanges,
    handleSaveEvent,
    updateCampaignId,
    updateEventName,
    updateEventLocation,
    updateGuestMaxLimit,
    updateStartTime,
    updateEndTime,
    updateEmailSettings,
    updateCalendarSettings,
    updateTimeZoneLocation,
    addTimeSlot,
    removeTimeSlot,
    updateTimeSlot,
    updateLegalText
  }

  return <UndoContext.Provider value={value}>{children}</UndoContext.Provider>
}

export default UndoProvider
