// A way to define all tanstack query keys so that we reuse the same logic in multiple places

export enum QueryKey {
  GET_ALL_PARTIES = 'GET_ALL_PARTIES'
}

export const queryKeyBuilders = {
  [QueryKey.GET_ALL_PARTIES]: (eventId: number) => [
    QueryKey.GET_ALL_PARTIES,
    eventId
  ]
}
