import InputContainer from 'components/Inputs/Container'
import Textarea from 'components/Inputs/Textarea'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import Text from 'components/Inputs/Text'

interface FormInputsProps {
  header: string
  headerContent: string
  body: string
  bodyContent: string
  handleInputChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

export const FormInputs: React.FC<FormInputsProps> = ({
  header,
  headerContent,
  body,
  bodyContent,
  handleInputChange
}) => {
  const { t } = useTranslation()

  return (
    <>
      <InputContainer id={header} label={t('header')}>
        <Text
          value={headerContent}
          handleChange={handleInputChange}
          placeholder={t('header')}
          name="header"
        />
      </InputContainer>
      <InputContainer id={body} label={t('body text')}>
        <Textarea
          value={bodyContent}
          handleChange={handleInputChange}
          placeholder={t('text')}
          name="content"
          handleBlur={undefined}
        />
      </InputContainer>
    </>
  )
}
