import clsx from 'clsx'
import { getStoredToken } from 'misc/api/storedToken'
import { GUESTLIST_API, stringifyParams } from 'misc/api/util'
import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './PreviewEmailModal.scss'

import Button from 'components/Button'
import Loader from 'components/Loader'
import { Actions, Content, Modal } from 'components/Modals/Modal'

const host =
  process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : ''

interface PreviewEmailModalProps {
  guestlistId: string | null
  handleClose: () => void
}

const PreviewEmailModal: FC<PreviewEmailModalProps> = ({
  guestlistId,
  handleClose
}) => {
  const { t } = useTranslation()
  const [isLoaded, setIsLoaded] = useState(false)

  const token = getStoredToken()

  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  return (
    <Modal modalName="PreviewEmailModal" size="medium">
      <Content>
        {guestlistId && (
          <iframe
            title="preview"
            src={`${host}${GUESTLIST_API}/email/${guestlistId}/preview${stringifyParams(
              { bearer_token: token?.token }
            )}`}
            frameBorder={0}
            ref={iframeRef}
            className={clsx('PreviewEmailModalIframe', { isLoaded })}
            onLoad={() => setIsLoaded(true)}
          />
        )}

        {!isLoaded && (
          <div className="PreviewEmailModalLoader">
            <Loader size={undefined} />
          </div>
        )}
      </Content>
      <Actions
        leftActions={
          <Button onClick={handleClose} type="secondary">
            {t('cancel')}
          </Button>
        }
      />
    </Modal>
  )
}

export default PreviewEmailModal
