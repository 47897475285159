/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import clsx from 'clsx'
import React from 'react'
import theme from 'theme'

interface InputContainerProps {
  id: string
  label: string
  className?: string
  children: React.ReactNode
}

const containerStyles = css`
  &:not(:first-of-type) {
    margin-top: ${theme.units.unit300}px;
  }
`

const InputContainer: React.FC<InputContainerProps> = ({
  id,
  label,
  children,
  className
}) => (
  <div css={containerStyles} className={clsx(className)}>
    <label
      htmlFor={id}
      css={css`
        display: block;
        margin-bottom: ${theme.units.unit200}px;
        font-size: 14px;
        font-weight: 500;
      `}>
      {label}
    </label>
    {children}
  </div>
)

export default InputContainer
