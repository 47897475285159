import { useState } from 'react'
import './AccordionTable.scss'

// Components
import TableHeader from 'components/AccordionTable/TableHeader'

import { FieldValue } from 'components/Modals/ManagePartyModal/types'
import { EventResponse } from 'misc/api/hooks/event.types'
import { Party } from 'misc/api/types'
import AccordionRow from './AccordionRow'
import { filterGuests } from './helper'

const fieldTypeOrder = [
  'FIRST_NAME',
  'LAST_NAME',
  'EMAIL',
  'COMPANY_NAME',
  'PHONE_NUMBER',
  'TEXT',
  'NUMBER',
  'BOOL',
  'SELECT_SINGLE_CHOICE',
  'SELECT_MULTIPLE_CHOICE'
]

const sortFields = (fields: EventResponse['fields']) =>
  fields.sort((a, b) => {
    // Will sort fields in the 'fieldTypeOrder'
    // all fields that are not present in the 'fieldTypeOrder' will be put last
    const indexA = fieldTypeOrder.indexOf(a.type)
    const indexB = fieldTypeOrder.indexOf(b.type)

    if (indexA === -1) return 1
    if (indexB === -1) return -1

    return indexA - indexB
  })

interface AccordionTableProps {
  event: EventResponse
  fields: EventResponse['fields']
  parties: Party[]
  cancelParty: (partyId: number) => void
  restoreParty: (partyId: number) => void
  deleteGuest: (guestId: number, partyId: number) => void
  toggleCancelGuest: (guestId: number, partyId: number) => void
  debug: boolean
  updateGuestDetails: (
    guestId: number,
    partyId: number,
    guestData: Record<string, FieldValue>
  ) => void
}
const AccordionTable = ({
  event,
  fields,
  parties,
  cancelParty,
  restoreParty,
  deleteGuest,
  toggleCancelGuest,
  debug,
  updateGuestDetails
}: AccordionTableProps) => {
  const [accordionOpen, setAccordionOpen] = useState<number | null>(null)

  if (parties.length === 0) {
    return null
  }

  const handleAccordionClick = (index: number) => {
    const open = accordionOpen === index ? null : index

    setAccordionOpen(open)
  }

  // Sort out current fieldtypes
  const leaderFields = filterGuests(fields, 'leader')
  const followerFields = filterGuests(fields, 'follower')
  const sortedLeaderFields = sortFields(leaderFields).slice(0, 4)
  const sortedFollowerFields = sortFields(followerFields).slice(0, 4)

  return (
    <div className="AccordionTable" data-debug={debug}>
      <TableHeader fields={sortedLeaderFields} />

      {parties.map((party, index) => {
        return (
          <AccordionRow
            key={party.id}
            party={party}
            index={index}
            event={event}
            cancelParty={cancelParty}
            restoreParty={restoreParty}
            deleteGuest={deleteGuest}
            toggleCancelGuest={toggleCancelGuest}
            updateGuestDetails={updateGuestDetails}
            accordionOpen={accordionOpen}
            sortedFollowerFields={sortedFollowerFields}
            sortedLeaderFields={sortedLeaderFields}
            leaderFields={leaderFields}
            followerFields={followerFields}
            handleAccordionClick={handleAccordionClick}
          />
        )
      })}
    </div>
  )
}

export default AccordionTable
