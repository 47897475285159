import { useState, useEffect, useCallback, useMemo } from 'react'
import API from 'misc/api'
import { Party } from 'misc/api/types'
import { EventResponse } from './event.types'
import { useFetchParties } from './useFetchParties'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeyBuilders } from '../queries'

const noop = () => {}

interface State {
  parties: Party[]
  checkinUrl: string | null
  fields: EventResponse['fields']
}

interface Handlers {
  updateParty: (party: Party) => void
  cancelParty: (party: Party) => void
}

export const useCheckIn = (event: EventResponse | null): [State, Handlers] => {
  const queryClient = useQueryClient()
  const [orgId, setOrgId] = useState<number | null>(null)
  const [checkinUrl, setCheckinUrl] = useState<string | null>(null)

  const useFetchPartiesQuery = useFetchParties({ eventId: event?.event })

  const parties = useMemo(() => useFetchPartiesQuery.data || [], [
    useFetchPartiesQuery.data
  ])

  const fetchOrgId = () =>
    API.getCurrentOrganization()
      .then(({ data, status }: { data: { id: number }; status: number }) => {
        if (status === 200) {
          setOrgId(data.id)
        }
      })
      .catch((error: any) => console.error(error))

  const updateParty = (party: Party) =>
    API.putParty(party.guestlist, party.id, party)
      .then(({ status }: { status: number }) => {
        if (status === 200) {
          if (event?.event) {
            queryClient.invalidateQueries({
              queryKey: queryKeyBuilders.GET_ALL_PARTIES(event?.event)
            })
          }
        }
      })
      .catch((error: any) => console.error(error))

  const cancelParty = (party: Party) =>
    API.cancelParty(party.guestlist, party.id, party)
      .then(({ status }: { status: number }) => {
        if (status === 200) {
          if (event?.event) {
            queryClient.invalidateQueries({
              queryKey: queryKeyBuilders.GET_ALL_PARTIES(event?.event)
            })
          }
        }
      })
      .catch((error: any) => console.error(error))

  const fetchCheckinUrl = useCallback(
    () =>
      orgId &&
      API.getCheckInAppUrlGuests(event?.event)
        .then(
          ({
            data,
            status
          }: {
            data: { relative: string }
            status: number
          }) => {
            if (status === 200) {
              setCheckinUrl(data.relative)
            }
          }
        )
        .catch((error: any) => console.error(error)),
    [orgId, event]
  )

  useEffect(() => {
    if (event) {
      fetchOrgId()
    }
  }, [event, event?.event])

  useEffect(() => {
    if (event) {
      fetchCheckinUrl()
    }
  }, [event, fetchCheckinUrl, orgId])

  const fields = event?.fields || []

  const state: State = { parties, checkinUrl, fields }
  const handlers: Handlers = { updateParty, cancelParty }

  return [state, handlers]
}
