export const theme = {
  unit: 8, // Base unit in pixels

  units: {
    unit100: 8 / 2, // 4px
    unit200: 8, // 8px
    unit300: 8 * 2, // 16px
    unit400: 8 * 3, // 24px
    unit500: 8 * 4, // 32px
    unit600: 8 * 5, // 40px
    unit700: 8 * 8, // 64px
    unit800: 8 * 12, // 96px
    unit900: 8 * 20 // 160px
  },

  container: {
    width: 1280 // 1280px
  },

  editor: {
    headerHeight: 120 // 120px
  },

  colors: {
    ink: '#151515',
    light: '#ffffff',

    blue: {
      base: '#4499f5',
      100: '#91c3f9',
      200: '#6baef7',
      300: '#57a4f6',
      400: '#4499f5',
      500: '#318ef4',
      600: '#1d84f3',
      700: '#0c70dc',
      800: '#0a5cb5',
      900: '#063567'
    },

    darkblue: {
      base: '#0d325b',
      100: '#1759a2',
      200: '#12467f',
      300: '#103c6d',
      400: '#0d325b',
      500: '#0a2849',
      600: '#081e37',
      700: '#030b14',
      800: '#000000',
      900: '#000000'
    },

    red: {
      base: '#f84f6e',
      100: '#fb9daf',
      200: '#fa768e',
      300: '#f9637e',
      400: '#f84f6e',
      500: '#f73b5e',
      600: '#f6284e',
      700: '#ec0933',
      800: '#c5082a',
      900: '#9e0622'
    },

    green: {
      base: '#29c166',
      100: '#80e4a8',
      200: '#5fdd91',
      300: '#3dd67a',
      400: '#29c166',
      500: '#229f54',
      600: '#1b7e42',
      700: '#145c31',
      800: '#104b28',
      900: '#0c3a1f'
    },

    grey: {
      base: '#8c8f95',
      100: '#e1e2e3',
      200: '#cccdd0',
      300: '#b6b8bc',
      400: '#8c8f95',
      500: '#777a81',
      600: '#63666c',
      700: '#505257',
      800: '#3c3e41',
      900: '#292a2c'
    },

    white: {
      100: '#ffffff',
      200: '#fafafa',
      300: '#f0f0f0',
      400: '#e6e6e6',
      500: '#dbdbdb',
      600: '#d1d1d1',
      700: '#c7c7c7',
      800: '#bdbdbd',
      900: '#b8b8b8'
    },

    purple: {
      base: '#8591b4',
      100: '#f0f2f6',
      200: '#eaecf2',
      300: '#cad0df',
      400: '#8591b4',
      500: '#6c7aa4',
      600: '#58668f',
      700: '#495476',
      800: '#39425d',
      900: '#293044'
    },

    orange: {
      base: '#ffa061',
      100: '#ffffff',
      200: '#ffeadb',
      300: '#ffd1b3',
      400: '#ffa061',
      500: '#ff8738',
      600: '#ff6f0f',
      700: '#e65c00',
      800: '#bd4b00',
      900: '#943b00'
    },

    assignments: {
      primary: '#3f90e6',
      primaryDisabled: '#2e70b7',
      primaryHover: '#4499f5',
      secondary: '#224b78',
      secondaryHover: '#4a6684',
      secondaryBright: '#f0f4f8',
      secondarySemi: '#284970',
      secondaryDark: '#0d325b',
      dark: '#082443',
      darkHover: '#0a2a4c',
      toolbar: '#547497',
      toolbarHover: '#3a6798',
      warning: '#e21042',
      focus: '#2297ff',
      success: '#29c166',
      successHover: '#3dd67a',
      draft: '#ec644b',
      closed: '#3c5a7b',
      active: '#15b75a',
      full: '#ef8a00'
    },

    default: {
      a1: '#8591b4',
      a2: '#a7b1c7',
      a3: '#d6dbe6',
      b1: '#285e99',
      b2: '#dee5ec',
      b3: '#eaeef3',
      b4: '#fafafc'
    },

    text: {
      color: '#151515'
    },

    common: {
      white: '#ffffff',
      black: '#151515'
    }
  }
}

export default theme
