/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FormattedFields } from './types'
import { theme } from 'theme'
import { EditIcon } from './EditIcon'

interface GuestComponentProps {
  guest: FormattedFields
  isLeader: boolean
  onEditClick: () => void
}

export const GuestComponent = ({
  guest,
  isLeader,
  onEditClick
}: GuestComponentProps) => (
  <div
    css={css`
      margin: ${theme.units.unit300}px 0;
      border: 1px solid #a7b1c7;
      padding: ${theme.units.unit400}px;
      font-size: 14px;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      cursor: default;
    `}>
    {guest.title && (
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid ${theme.colors.grey[100]};
          padding-bottom: ${theme.units.unit400}px;
          margin-bottom: ${theme.units.unit400}px;
        `}>
        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}>
          <span
            css={css`
              font-weight: 700;
            `}>
            {guest.title}
          </span>
          {isLeader && (
            <span
              css={css`
                color: #000;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
              `}>
              {/* space char */}
              {'\u00A0'}- Main guest
            </span>
          )}
        </div>
        <div>
          <div
            css={css`
              cursor: pointer;

              svg {
                fill: #285baf;
              }

              &:hover {
                svg {
                  fill: #3f90e6;
                }
              }

              display: flex;
              align-items: center;
              justify-content: center;
            `}
            onClick={onEditClick}>
            <EditIcon />
          </div>
        </div>
      </div>
    )}
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
      `}>
      {guest.dataFields.map((field, index) => {
        const { label, value = 'No response' } = field
        const parsedValue =
          field.type === 'BOOL' ? (field.value === true ? 'yes' : 'no') : value

        return (
          <div
            key={index}
            css={css`
              display: flex;
              justify-content: space-between;
              width: 100%;
              font-size: 14px;

              & + & {
                margin-top: ${theme.units.unit300}px;
              }
            `}>
            <div
              css={css`
                font-weight: 600;
              `}>
              {label}
            </div>
            <div>{parsedValue}</div>
          </div>
        )
      })}
    </div>
  </div>
)
