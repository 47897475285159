import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { GUESTLIST_API } from 'misc/api/util'
import {
  ValidationError,
  ValidationErrorDetails
} from './useUpdateGuestDetails.types'
import { FieldValue } from '../../types'

interface MutationFunctionResponse {
  data: any
}
/**
 * @throws {ValidationError}
 */
const mutationFn = async (args: {
  guestId: number
  guest_data: Record<string, FieldValue>
}): Promise<MutationFunctionResponse> => {
  try {
    const response = await axios({
      baseURL: GUESTLIST_API,
      method: 'PUT',
      url: `/guest/${args.guestId}`,
      data: {
        guest_data: args.guest_data
      }
    })

    return {
      data: response.data
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // check if it's a 400
      if (error.response?.status === 400) {
        const validationDetails: ValidationErrorDetails = {
          validationErrors: error.response.data.validation_errors,
          statusCode: error.response.status
        }

        throw new ValidationError(validationDetails)
      }
    }

    throw new Error('An error occurred')
  }
}

const useUpdateGuestDetails = () => {
  const mutation = useMutation({
    mutationFn
  })

  return {
    mutation
  }
}

export default useUpdateGuestDetails
