import { AxiosResponse } from 'axios'
import API from 'misc/api'
import React, { createContext, useEffect, useState } from 'react'
import { setLanguage, setTimezone } from '../../../i18n.js'
import { Organization, RootConfig, SettingKey } from './types.js'

export const OrganizationContext = createContext<{
  organization: Organization | null
  setOrganization: any
  settings: RootConfig | null
  updateSetting: any
  notifications: any[]
  setNotifications: any
  hiddenNotifications: any[]
  setHiddenNotifications: any
}>({
  organization: null,
  setOrganization: null,
  settings: null,
  updateSetting: null,
  notifications: [],
  setNotifications: null,
  hiddenNotifications: [],
  setHiddenNotifications: null
})

interface OrganizationProviderProps {
  children: React.ReactNode
}
const OrganizationProvider = ({ children }: OrganizationProviderProps) => {
  const [organization, setOrganization] = useState<null | Organization>(null)
  const [settings, setSettings] = useState<null | RootConfig>(null)
  const [notifications, setNotifications] = useState<any[]>([])
  const [hiddenNotifications, setHiddenNotifications] = useState<any[]>([])

  const fetchSettings = () =>
    API.getOrganizationSettings(organization?.id)
      .then(({ data, status }: AxiosResponse<RootConfig>) => {
        if (status === 200) {
          setTimezone(data.time_preferences.timezone)
          setLanguage(data.lang_preferences.language)
          setSettings(data)
        }
      })
      .catch(error => console.error(error))

  const updateSetting = (key: SettingKey, data: any) =>
    API.putOrganizationSetting(organization?.id, key, data)
      .then(({ data, status }) => {
        if (status === 200) {
          setSettings((prev): RootConfig | null => {
            if (!prev) return prev

            if (!data) {
              return prev
            }

            return {
              ...prev,
              [key]: data
            }
          })
        }
      })
      .catch(error => console.log(error))

  const fetchNotifications = () =>
    API.getOrganizationNotifications()
      .then(({ data, status }) => {
        if (status === 200) {
          setNotifications(data.notifications)
        }
      })
      .catch(error => console.error(error))

  useEffect(() => {
    organization &&
      // @ts-expect-error I don't want to modify this shitty code
      fetchSettings() &&
      fetchNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        setOrganization,
        settings,
        updateSetting,
        notifications,
        setNotifications,
        hiddenNotifications,
        setHiddenNotifications
      }}>
      {children}
    </OrganizationContext.Provider>
  )
}

export default OrganizationProvider
