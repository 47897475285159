/** @jsxImportSource @emotion/react */
import React, { useRef, useEffect } from 'react'
import { css } from '@emotion/react'
import theme from 'theme'

type DropdownProps = {
  options: Array<{ label: string; value: string }>
  selectedValue?: string
  onChange: (value: string | undefined) => void
  placeholder?: string
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  selectedValue,
  onChange,
  placeholder = 'Select an option'
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => setIsOpen(prev => !prev)

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isOpen])

  const selectedOption = options.find(option => option.value === selectedValue)

  const inputSize = '48px'
  const inset = theme.units.unit300
  const iconLength = '10px'
  const borderRadius = theme.units.unit100

  const containerStyle = css`
    position: relative;
    width: 100%;
    font-family: Montserrat, Arial, sans-serif;
  `

  const buttonStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: ${inputSize};
    padding: 0 ${inset}px;
    border: 1px solid ${theme.colors.grey[100]};
    border-radius: 0 ${borderRadius}px ${borderRadius}px 0;
    background-color: ${theme.colors.white[100]};
    cursor: pointer;
    color: ${theme.colors.grey[700]};
    text-align: left;
    position: relative;
    transition: border-color 0.2s, color 0.2s;

    &:hover,
    &:focus {
      border-color: ${theme.colors.blue[700]};
      color: ${theme.colors.common.black};

      svg {
        fill: ${theme.colors.grey[700]};
      }
    }
  `

  const iconStyle = css`
    position: absolute;
    right: ${inset}px;
    top: 50%;
    margin-top: calc(-${iconLength} / 2);
    width: ${iconLength};
    height: ${iconLength};
    fill: ${theme.colors.default.b2};
    transition: fill 0.2s;
  `

  const dropdownListStyle = css`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: ${theme.units.unit200}px;
    background-color: ${theme.colors.white[100]};
    border: 1px solid ${theme.colors.grey[100]};
    border-radius: ${theme.units.unit100}px;
    max-height: 311px;
    overflow-y: auto;
    z-index: 2;
  `

  const itemStyle = (isSelected: boolean) => css`
    display: block;
    width: 100%;
    padding: ${theme.units.unit300}px;
    border: 0px;
    border-bottom: 1px solid ${theme.colors.grey[100]};
    text-align: left;
    cursor: pointer;
    background-color: ${isSelected
      ? theme.colors.blue.base
      : theme.colors.white[100]};
    color: ${isSelected ? theme.colors.white[100] : theme.colors.grey[700]};
    transition: background-color 0.2s, color 0.2s;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: ${isSelected
        ? theme.colors.blue.base
        : theme.colors.white[300]};
      color: ${isSelected ? theme.colors.white[100] : theme.colors.grey[700]};
    }
  `

  const CheIcon = () => (
    <svg css={iconStyle} viewBox="0 0 20 20">
      <g>
        <path d="M19.6287479,5.38478143 C19.3815202,5.12818954 19.0884588,5 18.7498375,5 L1.25009406,5 C0.911335947,5 0.61847983,5.12818954 0.371046853,5.38478143 C0.123613876,5.64165709 0,5.9453535 0,6.29643818 C0,6.64745192 0.123613876,6.95114832 0.371046853,7.20781116 L9.12095279,16.2816015 C9.3686594,16.5381934 9.66151552,16.6666667 10,16.6666667 C10.3384845,16.6666667 10.6316142,16.5381934 10.878842,16.2816015 L19.6287479,7.20774022 C19.8759073,6.95114832 20,6.64745192 20,6.29636724 C20,5.9453535 19.8759073,5.64165709 19.6287479,5.38478143 Z" />
      </g>
    </svg>
  )

  return (
    <div css={containerStyle} ref={containerRef}>
      <button css={buttonStyle} onClick={toggleDropdown}>
        <span>{selectedOption ? selectedOption.label : placeholder}</span>
        <CheIcon />
      </button>
      {isOpen && (
        <div css={dropdownListStyle}>
          <button
            css={itemStyle(false)}
            onClick={() => {
              onChange(undefined)
              setIsOpen(false)
            }}>
            Clear Selection
          </button>
          {options.map(option => (
            <button
              key={option.value}
              css={itemStyle(option.value === selectedValue)}
              onClick={() => {
                onChange(option.value)
                setIsOpen(false)
              }}>
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
