import React from 'react'

export const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3367 4.23742C16.0201 3.554 17.1282 3.554 17.8116 4.23742L19.2258 5.65163C19.9092 6.33505 19.9092 7.44309 19.2258 8.1265L9.18684 18.1655C8.94254 18.4098 8.63139 18.5763 8.29261 18.644L6.52484 18.9976C5.30033 19.2425 4.22072 18.1629 4.46562 16.9384L4.81917 15.1706C4.88693 14.8318 5.05345 14.5207 5.29775 14.2764L15.3367 4.23742ZM16.7509 5.29808C16.6533 5.20045 16.495 5.20045 16.3974 5.29808L15.5135 6.18196L17.2812 7.94973L18.1651 7.06584C18.2628 6.96821 18.2628 6.80992 18.1651 6.71229L16.7509 5.29808ZM16.2206 9.01039L14.4528 7.24262L8.44242 13.253L10.2102 15.0208L16.2206 9.01039ZM9.14952 16.0815L7.38176 14.3137L6.35841 15.337C6.32351 15.3719 6.29972 15.4164 6.29005 15.4648L5.93649 17.2325C5.90151 17.4075 6.05574 17.5617 6.23067 17.5267L7.99843 17.1732C8.04683 17.1635 8.09128 17.1397 8.12618 17.1048L9.14952 16.0815Z"
      />
    </svg>
  )
}
