import { isEqual, cloneDeep, omit } from 'lodash'
import { formFactorys } from 'components/Contexts/UndoProvider/helpers/data'

// Types
interface Field {
  guest_type?: string
  order_index?: number
  label?: string
  required?: boolean
  data?: {
    options?: any
  }
  [key: string]: any
}

interface FormFactory {
  optional?: boolean
  fields: Field[]
  [key: string]: any
}

interface EventData {
  event?: string | null
  last_modified?: string | null
  fields?: Field[]
  data?: Record<string, any>
  guestlists?: Array<{
    key: number
    end_time: string
    event?: string
    guest_max_limit?: number | null
    organization?: string
    start_time: string
    name?: string
  }>
  [key: string]: any
}

export interface CurrentState extends EventData {
  max_number_of_followers: number
  data: Record<string, any>
  formFactorys: FormFactory[]
}

interface State {
  history: CurrentState[]
  index: number
  currentState: CurrentState
}

type Payload = {
  event?: EventData
  fieldIndex?: number
  fieldData?: Field
  factoryId?: number
  fieldOptionsList?: any
  page?: string
  fieldName?: string
  value?: any
  checked?: boolean
  relativeIndex?: number
  maxNrOfFollowers?: number
  attribute?: string
}

interface Action {
  type: string
  payload?: Payload & {
    destination?: any
    source?: any
  }
}

export { formFactorys }

export const getFormEvent = (currentState: CurrentState): CurrentState => {
  const currentEventState = {
    ...currentState,
    fields: currentState.formFactorys.reduce(
      (fields: Field[], current: FormFactory) => [...fields, ...current.fields],
      []
    )
  }
  delete (currentEventState as any).formFactorys
  return currentEventState
}

const blacklist = [
  'checkins',
  'last_modified',
  'preview_image',
  'published',
  'sign_ups',
  'state'
]

export const hasFormBeenUpdated = (
  event: EventData | undefined,
  currentState: CurrentState
) => {
  if (!event || typeof currentState.event === 'undefined') {
    return false
  }

  const currentEventState = getFormEvent(currentState)

  if (currentState.event !== event.event) {
    return false
  }

  const eventData = {
    ...event,
    data: {
      ...(event && event.data)
    }
  }

  return !isEqual(
    omit(eventData, blacklist),
    omit(currentEventState, blacklist)
  )
}

const createHistoryIndex = (
  currentState: CurrentState,
  history: CurrentState[],
  index: number
) => {
  const newHistoryState = cloneDeep(currentState)

  if (index + 1 !== history.length) {
    history.splice(index + 1)
    history.push(newHistoryState)
    return { history, index: history.length - 1 }
  }
  history.push(newHistoryState)
  return { history, index: index + 1 }
}

const createStateWithFormfactory = (
  event: EventData,
  currentState: CurrentState
): CurrentState => {
  const filterFields = (fields: Field[], guestType: string) =>
    fields.filter(({ guest_type }) => guest_type === guestType)

  const formFactorys = currentState.formFactorys.map((factory, index) => ({
    ...factory,
    fields: filterFields(
      cloneDeep(event.fields || []),
      index === 0 ? 'leader' : 'follower'
    )
  }))
  const data = {
    ...event.data
  }

  return {
    ...currentState,
    ...event,
    data,
    formFactorys
  }
}

const removeFieldFromFactory = (
  formFactorys: FormFactory[],
  factoryId: number,
  fieldIndex: number
) => {
  const fields = cloneDeep(formFactorys[factoryId].fields)
  fields.splice(fieldIndex, 1)
  formFactorys[factoryId].fields = fields
  return formFactorys
}

export const reducer = (prevState: State, action: Action): State => {
  const {
    history,
    index,
    currentState,
    currentState: { formFactorys }
  } = prevState
  const {
    type,
    payload: {
      event,
      fieldIndex,
      fieldData,
      factoryId,
      fieldOptionsList,
      page,
      fieldName,
      value,
      checked,
      relativeIndex,
      maxNrOfFollowers,
      attribute,
      destination,
      source
    } = {}
  } = action

  switch (type) {
    case 'FieldCreate': {
      if (typeof factoryId === 'undefined' || !fieldData) return prevState
      const newField: Field = {
        ...fieldData,
        order_index:
          formFactorys[factoryId].fields.length > 0
            ? (formFactorys[factoryId].fields
                .filter(field => field.guest_type === fieldData.guest_type)
                .reduce(
                  (maxValue, currentValue) =>
                    (maxValue.order_index || 0) >
                    (currentValue.order_index || 0)
                      ? maxValue
                      : currentValue,
                  {}
                ).order_index || 0) + 1
            : 0
      }
      formFactorys[factoryId].fields.push(newField)
      return {
        ...prevState,
        currentState: { ...currentState, formFactorys },
        ...createHistoryIndex(currentState, history, index)
      }
    }

    case 'FieldDelete': {
      if (typeof factoryId === 'undefined' || typeof fieldIndex === 'undefined')
        return prevState
      const removedFieldFactory = removeFieldFromFactory(
        formFactorys,
        factoryId,
        fieldIndex
      )
      return {
        ...prevState,
        currentState: { ...currentState, formFactorys: removedFieldFactory },
        ...createHistoryIndex(
          { ...currentState, formFactorys: removedFieldFactory },
          history,
          index
        )
      }
    }

    case 'FieldChange': {
      if (typeof factoryId === 'undefined' || typeof fieldIndex === 'undefined')
        return prevState
      formFactorys[factoryId].fields[fieldIndex].label = value
      return {
        ...prevState,
        currentState: { ...currentState, formFactorys }
      }
    }

    case 'FieldRequiredChange': {
      if (typeof factoryId === 'undefined' || typeof fieldIndex === 'undefined')
        return prevState
      formFactorys[factoryId].fields[fieldIndex].required = checked || false
      return {
        ...prevState,
        currentState: { ...currentState, formFactorys },
        ...createHistoryIndex({ ...currentState, formFactorys }, history, index)
      }
    }

    case 'FieldTextAreaChange': {
      if (typeof factoryId === 'undefined' || typeof fieldIndex === 'undefined')
        return prevState
      formFactorys[factoryId].fields[fieldIndex].data = {
        ...formFactorys[factoryId].fields[fieldIndex].data,
        options: fieldOptionsList
      }
      return {
        ...prevState,
        currentState: { ...currentState, formFactorys }
      }
    }

    case 'DescriptionFieldChange': {
      if (!page || !fieldName) return prevState
      const currentPage = currentState.data[page]
      return {
        ...prevState,
        currentState: {
          ...currentState,
          data: {
            ...currentState.data,
            [page]: {
              ...currentPage,
              [fieldName]: value
            }
          }
        }
      }
    }

    case 'MultiplePageChange': {
      if (!fieldName) return prevState
      const pages = ['closed', 'confirm', 'full', 'signup']
      return {
        ...prevState,
        currentState: {
          ...currentState,
          data: pages.reduce(
            (acc, key) => ({
              ...acc,
              [key]: {
                ...currentState.data[key],
                [fieldName]: value
              }
            }),
            currentState.data
          )
        }
      }
    }

    case 'DragEndChange': {
      if (!destination || !source) return prevState
      const sourceFactoryId = parseInt(source.droppableId.split('-')[1], 10)
      const destinationFactoryId = parseInt(
        destination.droppableId.split('-')[1],
        10
      )
      const field = formFactorys[sourceFactoryId].fields.splice(source.index, 1)
      formFactorys[destinationFactoryId].fields.splice(
        destination.index,
        0,
        field[0]
      )
      formFactorys[destinationFactoryId].fields = formFactorys[
        destinationFactoryId
      ].fields.map((f, i) => {
        f.order_index = i + 1
        return f
      })
      return {
        ...prevState,
        currentState: { ...currentState, formFactorys },
        ...createHistoryIndex({ ...currentState, formFactorys }, history, index)
      }
    }

    case 'GuestCountChange':
      return {
        ...prevState,
        currentState: {
          ...currentState,
          max_number_of_followers: maxNrOfFollowers || 0
        }
      }

    case 'CreateInitialHistory': {
      if (!event) return prevState
      const eventData = createStateWithFormfactory(event, currentState)
      return {
        ...prevState,
        currentState: eventData,
        history: [cloneDeep(eventData)],
        index: 0
      }
    }

    case 'SetCurrentState': {
      if (!event) return prevState
      return {
        ...prevState,
        currentState: createStateWithFormfactory(event, currentState)
      }
    }

    case 'UpdateHistory':
      return {
        ...prevState,
        ...createHistoryIndex(currentState, history, index)
      }

    case 'MoveHistoryPointer': {
      if (typeof relativeIndex === 'undefined') return prevState
      const newIndex = index + relativeIndex
      const newCurrentState = cloneDeep(history[newIndex])
      return {
        ...prevState,
        currentState: newCurrentState,
        index: newIndex
      }
    }

    case 'SetDefaultNrOfFollowers':
      return {
        ...prevState,
        currentState: {
          ...currentState,
          max_number_of_followers: 1
        }
      }

    case 'UpdateEventStateAttribute':
      if (!attribute) return prevState
      return {
        ...prevState,
        currentState: { ...currentState, [attribute]: value }
      }

    case 'UpdateEventDataAttribute':
      if (!attribute) return prevState
      return {
        ...prevState,
        currentState: {
          ...currentState,
          data: {
            ...currentState.data,
            [attribute]: value
          }
        }
      }

    default:
      return prevState
  }
}
