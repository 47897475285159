/** @jsxImportSource @emotion/react */
import './Modal.scss'
import React, { ReactNode } from 'react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'
import { SerializedStyles } from '@emotion/react'

const modalRoot = document.getElementById('modal-root')

// ------------------- Subcomponents -------------------

interface HeaderProps {
  children: ReactNode
  customCss?: SerializedStyles
}

export const Header = ({ children, customCss }: HeaderProps) => (
  <div className="Header" css={customCss}>
    {children}
  </div>
)

interface ContentProps {
  children: ReactNode
  fluid?: boolean
  customCss?: SerializedStyles
}

export const Content = ({ children, fluid, customCss }: ContentProps) => (
  <div className={`Content${fluid ? ' fluid' : ''}`} css={customCss}>
    {children}
  </div>
)

interface ActionsProps {
  leftActions?: ReactNode
  rightActions?: ReactNode
}

export const Actions = ({ leftActions, rightActions }: ActionsProps) => (
  <div
    className={clsx(['Actions', { ActionsFull: leftActions && rightActions }])}>
    {leftActions}
    {rightActions}
  </div>
)

// ------------------- Modal Component -------------------

interface ModalProps {
  children: ReactNode
  size?: string
  debug?: boolean
  modalName?: string
  maxHeight?: number
}

export class Modal extends React.Component<ModalProps> {
  modalContainer: HTMLDivElement

  constructor(props: ModalProps) {
    super(props)
    this.modalContainer = document.createElement('div')
  }

  componentDidMount() {
    document.documentElement.style.overflowY = 'hidden'

    if (!this.props.debug && modalRoot) {
      modalRoot.appendChild(this.modalContainer)
    }
  }

  componentWillUnmount() {
    document.documentElement.style.overflowY = ''

    if (!this.props.debug && modalRoot) {
      modalRoot.removeChild(this.modalContainer)
    }
  }

  render() {
    const { children, size, debug, modalName, maxHeight } = this.props

    if (debug) {
      return (
        <div className="Modal" aria-modal="true">
          <div className="wrapper">
            <div
              className={clsx(['container', { Scrollable: !!maxHeight }])}
              data-size={size}
              style={{ maxHeight: maxHeight ? `${maxHeight}px` : undefined }}>
              {children}
            </div>
          </div>
        </div>
      )
    }

    return ReactDOM.createPortal(
      <div className="Modal" data-modal={modalName}>
        <div className="wrapper">
          <div
            className={clsx(['container', { Scrollable: !!maxHeight }])}
            data-size={size}
            style={{ maxHeight: maxHeight ? `${maxHeight}px` : undefined }}>
            {children}
          </div>
        </div>
      </div>,
      this.modalContainer
    )
  }
}

export default Modal
