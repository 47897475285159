import {
  FieldKey,
  FieldLabel,
  FieldType,
  VisualPlacing
} from 'misc/field.types'

export const formFactorys = [
  {
    title: 'primary guest',
    optional: false,
    fields: []
  },
  {
    title: 'secondary guests',
    optional: true,
    fields: []
  }
]
interface Field {
  key: FieldKey
  type: FieldType
  visual_placing: VisualPlacing
  label: FieldLabel
  data?: {
    options?: Array<{ value: string }>
  }
}

export const availableFields: Field[] = [
  // First Name
  {
    key: FieldKey.FIRST_NAME,
    type: FieldType.FIRST_NAME,
    visual_placing: VisualPlacing.FIRST_NAME,
    label: FieldLabel.FIRST_NAME
  },
  // Last Name
  {
    key: FieldKey.LAST_NAME,
    type: FieldType.LAST_NAME,
    visual_placing: VisualPlacing.LAST_NAME,
    label: FieldLabel.LAST_NAME
  },
  // Company Name
  {
    key: FieldKey.COMPANY_NAME,
    type: FieldType.COMPANY_NAME,
    visual_placing: VisualPlacing.COMPANY_NAME,
    label: FieldLabel.COMPANY_NAME
  },
  // E-mail
  {
    key: FieldKey.EMAIL,
    type: FieldType.EMAIL,
    visual_placing: VisualPlacing.EMAIL,
    label: FieldLabel.EMAIL
  },
  // Phone Number
  {
    key: FieldKey.PHONE_NUMBER,
    type: FieldType.PHONE_NUMBER,
    visual_placing: VisualPlacing.PHONE_NUMBER,
    label: FieldLabel.PHONE_NUMBER
  },
  // Text
  {
    key: FieldKey.TEXT,
    type: FieldType.TEXT,
    visual_placing: VisualPlacing.TEXT,
    label: FieldLabel.TEXT
  },
  // Number
  {
    key: FieldKey.NUMBER,
    type: FieldType.NUMBER,
    visual_placing: VisualPlacing.NUMBER,
    label: FieldLabel.NUMBER
  },
  // Checkbox
  {
    key: FieldKey.BOOL,
    type: FieldType.BOOL,
    visual_placing: VisualPlacing.BOOL,
    label: FieldLabel.BOOL
  },
  // Multiple Selection Checkbox
  {
    key: FieldKey.SELECT_MULTIPLE_CHOICE,
    type: FieldType.SELECT_MULTIPLE_CHOICE,
    visual_placing: VisualPlacing.BOOL,
    label: FieldLabel.SELECT_MULTIPLE_CHOICE,
    data: {
      options: [{ value: 'option' }, { value: 'option' }]
    }
  },
  // Dropdown
  {
    key: FieldKey.SELECT_SINGLE_CHOICE,
    type: FieldType.SELECT_SINGLE_CHOICE,
    visual_placing: VisualPlacing.SELECT_BOX,
    label: FieldLabel.SELECT_SINGLE_CHOICE,
    data: {
      options: [{ value: 'option' }, { value: 'option' }]
    }
  },
  // Radiobutton
  {
    key: FieldKey.RADIO_SELECTION,
    type: FieldType.SELECT_SINGLE_CHOICE,
    visual_placing: VisualPlacing.RADIO_SELECTION,
    label: FieldLabel.RADIO_SELECTION,
    data: {
      options: [{ value: 'option' }, { value: 'option' }]
    }
  }
]
