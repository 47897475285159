import { skipToken, useQuery } from '@tanstack/react-query'
import API from 'misc/api'
import { queryKeyBuilders } from '../queries'
import { Party } from '../types'

interface Props {
  eventId: number | undefined
  select?: (data: Party[]) => Party[]
}
export const useFetchParties = ({ eventId, select }: Props) => {
  const query = useQuery({
    select,
    queryKey: queryKeyBuilders.GET_ALL_PARTIES(eventId!),
    queryFn: eventId
      ? async (): Promise<Party[]> => {
          const response = await API.getAllParties(eventId)

          return response.data
        }
      : skipToken,
    // referential equality instead of deep equality
    // very important so that we get latest version of deep nested objects
    structuralSharing: false
  })

  return query
}
