import { FieldType } from 'misc/field.types'
import { z } from 'zod'

export const optionalFieldValidators = {
  [FieldType.FIRST_NAME]: z.string().optional(),
  [FieldType.LAST_NAME]: z.string().optional(),
  [FieldType.COMPANY_NAME]: z.string().optional(),
  [FieldType.EMAIL]: z
    .string()
    .email({
      message: 'Incorrect e-mail address format'
    })
    .optional(),
  [FieldType.PHONE_NUMBER]: z
    .string()
    .optional()
    .refine(val => !val || /^[+][0-9]+$/.test(val), {
      message:
        'Incorrect phone number format. Make sure country code is included.'
    }),
  [FieldType.SELECT_SINGLE_CHOICE]: z.string().optional(),
  [FieldType.SELECT_MULTIPLE_CHOICE]: z.array(z.string()).optional(),
  [FieldType.TEXT]: z.string().optional(),
  [FieldType.NUMBER]: z
    .number({
      message: 'Incorrect number format'
    })
    .optional(),
  [FieldType.BOOL]: z.boolean().optional()
} as const

export const requiredFieldValidators = {
  [FieldType.FIRST_NAME]: z.string().min(1),
  [FieldType.LAST_NAME]: z.string().min(1),
  [FieldType.COMPANY_NAME]: z.string().min(1),
  [FieldType.EMAIL]: z
    .string()
    .email({
      message: 'Incorrect e-mail address format'
    })
    .min(1),
  [FieldType.PHONE_NUMBER]: z
    .string()
    .min(1)
    .refine(val => !val || /^[+][0-9]+$/.test(val), {
      message:
        'Incorrect phone number format. Make sure country code is included.'
    }),
  [FieldType.SELECT_SINGLE_CHOICE]: z.string().min(1),
  [FieldType.SELECT_MULTIPLE_CHOICE]: z.array(z.string()).min(1, {
    message: 'At least one option must be selected'
  }),
  [FieldType.TEXT]: z.string().min(1),
  [FieldType.NUMBER]: z.number({
    message: 'Incorrect number format'
  }),
  // Needs to be optional because the checkbox can be unchecked
  [FieldType.BOOL]: z.boolean().optional()
} as const
