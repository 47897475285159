import AccordionTable from 'components/AccordionTable'
import EmptyState from 'components/AccordionTable/EmptyState'
import { FieldValue } from 'components/Modals/ManagePartyModal/types'
import { EventResponse, Field, Guestlist } from 'misc/api/hooks/event.types'
import { Party } from 'misc/api/types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface EventResultSectionProps {
  parties: Party[]
  filterGuests: (parties: Party[]) => Party[]
  event: EventResponse
  fields: Field[]
  toggleCancelGuest: (guestId: number, partyId: number) => void
  cancelPartyHandler: (partyId: number) => void
  restorePartyHandler: (partyId: number) => void
  deleteGuest: (guestId: number, partyId: number) => void
  guestDetailsHaveBeenUpdated: (
    guestId: number,
    partyId: number,
    guestData: Record<string, FieldValue>
  ) => void
  search: string
  guestlist: Guestlist
}

export function EventResultSection({
  parties,
  filterGuests,
  event,
  fields,
  toggleCancelGuest,
  cancelPartyHandler,
  restorePartyHandler,
  deleteGuest,
  guestDetailsHaveBeenUpdated,
  search,
  guestlist
}: EventResultSectionProps) {
  const { t } = useTranslation()

  const partiesInGuestlist = parties.filter(
    party => party.guestlist === guestlist.id
  )
  const filteredGuests = filterGuests(partiesInGuestlist)
  const numberOfAttendingGuests = partiesInGuestlist.reduce(
    (acc, party) => acc + party.guests.filter(g => !g.canceled).length,
    0
  )

  return (
    <section className="EventResultSection">
      {event.timeslots_enabled ? (
        <header className="EventResultHeader">
          <div className="EventResultData">
            {guestlist.name ? (
              <h3 className="EventResultName">{guestlist.name}</h3>
            ) : null}

            <div>
              <time className="EventResultTime" dateTime={guestlist.start_time}>
                {moment
                  .parseZone(guestlist.start_time)
                  .format('YYYY-MM-DD, HH:mm')}
              </time>
              –
              <time className="EventResultTime" dateTime={guestlist.end_time}>
                {moment.parseZone(guestlist.end_time).format('HH:mm')}
              </time>
            </div>
          </div>

          <div className="EventResultMeta">
            <div className="EventResultMetaColumn">
              <div className="EventResultMetaNumber">
                {numberOfAttendingGuests}
              </div>
              {t('guests')}
            </div>
            <div className="EventResultMetaColumn">
              <div className="EventResultMetaNumber">
                {guestlist.guest_max_limit ||
                  '–' /* null and 0 should render a dash */}
              </div>
              {t('guest limit')}
            </div>
          </div>
        </header>
      ) : null}

      {filteredGuests.length > 0 ? (
        <AccordionTable
          event={event}
          fields={fields}
          parties={filteredGuests}
          toggleCancelGuest={toggleCancelGuest}
          cancelParty={cancelPartyHandler}
          restoreParty={restorePartyHandler}
          deleteGuest={deleteGuest}
          debug={false}
          updateGuestDetails={guestDetailsHaveBeenUpdated}
        />
      ) : (
        <EmptyState
          message={t(
            partiesInGuestlist.length > 0 && search
              ? 'no results'
              : 'empty guestlist'
          )}
        />
      )}
    </section>
  )
}
