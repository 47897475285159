/** @jsxImportSource @emotion/react */
import Toggle from 'components/Toggle'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import SettingsModule from '../SettingsModule'

import { css } from '@emotion/react'
import { ConfirmationEmailSettings } from './types'
import InputContainer from 'components/Inputs/Container'
import DateTimePicker from 'components/DateTimePicker'
import { EventResponse } from 'misc/api/hooks/event.types'
import { ceilToMinutes } from 'misc/calendar'
import moment from 'moment'

interface AllowGuestToCancelSettingsProps {
  confirmationEmail: ConfirmationEmailSettings
  updateEmailSettings: (val: Partial<ConfirmationEmailSettings>) => void
  confirmationEmailActive: boolean
  event: EventResponse | null
}

export const AllowGuestToCancelSettings: React.FC<AllowGuestToCancelSettingsProps> = ({
  confirmationEmail,
  updateEmailSettings,
  confirmationEmailActive,
  event
}) => {
  const { t } = useTranslation()

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (confirmationEmailActive) {
      const newValue: Partial<ConfirmationEmailSettings> = {
        ...confirmationEmail,
        allow_guest_to_cancel: {
          enabled: e.target.checked,
          last_date: lastDate
        }
      }

      updateEmailSettings(newValue)
    }
  }

  const handleDateChange = (date: string) => {
    const newValue = {
      ...confirmationEmail,
      allow_guest_to_cancel: {
        ...confirmationEmail.allow_guest_to_cancel,
        last_date: date
      }
    }

    updateEmailSettings(newValue)
  }

  const canCancel = confirmationEmail.allow_guest_to_cancel?.enabled

  const timezone = event?.data?.timezone_location

  if (!timezone) {
    return null
  }

  const lastDate =
    confirmationEmail.allow_guest_to_cancel?.last_date ||
    ceilToMinutes(moment().tz(timezone), 30).format()

  return (
    <SettingsModule
      css={css`
        .SettingsModuleInset {
          padding-top: 0;
          padding-bottom: 0;
        }
      `}
      title={t('allow_guests_to_cancel')}
      className="AllowGuestToCancelSettings"
      rightContent={
        <div className="SettingsModuleSpacer">
          <Toggle
            size="large"
            id="allow_guest_to_cancel"
            name="allow_guest_to_cancel"
            handleChange={handleToggleChange}
            disabled={!canCancel}
            label={undefined}
          />
        </div>
      }
      description={undefined}
      size={undefined}>
      {canCancel && (
        <div
          css={css`
            display: flex;
            padding-top: 32px;
            padding-bottom: 32px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;
          `}>
          <InputContainer
            label={t('last_day_to_cancel')}
            id={'last_day_to_cancel'}>
            <DateTimePicker
              value={lastDate}
              handleChange={handleDateChange}
              timezone={timezone}
              readOnly={undefined}
              dayErrored={undefined}
              timeErrored={undefined}
            />
          </InputContainer>
        </div>
      )}
    </SettingsModule>
  )
}
