import Toggle from 'components/Toggle'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import SettingsModule from '../SettingsModule'
import { ConfirmationEmailSettings } from './types'

interface QrTicketSettingsProps {
  confirmationEmailActive: boolean
  updateEmailSettings: (val: Partial<ConfirmationEmailSettings>) => void
  confirmationEmail: ConfirmationEmailSettings
}

export const QrTicketSettings: React.FC<QrTicketSettingsProps> = ({
  confirmationEmailActive,
  updateEmailSettings,
  confirmationEmail
}) => {
  const { t } = useTranslation()

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    updateEmailSettings({
      ...confirmationEmail,
      [name]: checked
    })
  }

  const { qr_ticket_enabled = false } = confirmationEmail

  return (
    <div id="qr_ticket_enabled_container">
      <SettingsModule
        title={t('qr_code_ticket')}
        className="CalendarSettings"
        description={t('qr_ticket_description')}
        rightContent={
          <div className="SettingsModuleSpacer">
            <Toggle
              size="large"
              id="qr_ticket_enabled"
              name="qr_ticket_enabled"
              handleChange={handleToggleChange}
              disabled={!qr_ticket_enabled || !confirmationEmailActive}
              label={undefined}
            />
          </div>
        }
        children={undefined}
        size={undefined}
      />
    </div>
  )
}
