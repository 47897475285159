import { ErrorContext } from 'components/Contexts/ErrorProvider'
import Toggle from 'components/Toggle'
import { useContext, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import SettingsModule from '../SettingsModule'

import InputContainer from 'components/Inputs/Container'
import Text from 'components/Inputs/Text'
import { FormInputs } from './FormInput'

export interface CalendarReminderSettings {
  name?: string
  header?: string
  content?: string
  enabled?: boolean
}

interface CalendarSettingsProps {
  calendarReminder: CalendarReminderSettings
  updateCalendarSettings: (val: Partial<CalendarReminderSettings>) => void
  confirmationEmailActive: boolean
}

export const CalendarSettings: React.FC<CalendarSettingsProps> = ({
  calendarReminder,
  updateCalendarSettings,
  confirmationEmailActive
}) => {
  const { t } = useTranslation()
  const { error, setError } = useContext(ErrorContext)
  const nameIsInvalid =
    error && error?.data?.detail?.error_type === 'reminder_name'

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (confirmationEmailActive) {
      updateCalendarSettings({
        ...calendarReminder,
        enabled: e.target.checked
      })
    }
  }

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setError('')
    const { value, name } = e.target
    updateCalendarSettings({
      ...calendarReminder,
      [name]: value
    })
  }

  const handleInputBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    updateCalendarSettings({
      ...calendarReminder,
      [name]: value.trim()
    })
  }

  const {
    name = '',
    header = '',
    content = '',
    enabled = false
  } = calendarReminder

  return (
    <SettingsModule
      title={t('calendar reminder')}
      className="CalendarSettings"
      rightContent={
        <div className="SettingsModuleSpacer">
          <Toggle
            size="large"
            id="calendar"
            handleChange={handleToggleChange}
            disabled={!enabled || !confirmationEmailActive}
            label={undefined}
          />
        </div>
      }
      description={undefined}
      size={undefined}>
      {enabled && (
        <div className="CalendarContent">
          <InputContainer id="emailSubject" label={t('reminderName')}>
            <Text
              value={name}
              handleChange={handleInputChange}
              handleBlur={handleInputBlur}
              placeholder="reminder"
              name="name"
            />
          </InputContainer>
          {nameIsInvalid && (
            <p className="SettingsModuleDescription error">
              {t('helpTexts.invalidReminderName')}
            </p>
          )}
          <FormInputs
            header="calendarTitle"
            headerContent={header}
            body="calendarContent"
            bodyContent={content}
            handleInputChange={handleInputChange}
          />
        </div>
      )}
    </SettingsModule>
  )
}
