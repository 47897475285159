import './EventResult.scss'

// Hooks
import { useCheckIn } from 'misc/api'
import { Trans, useTranslation } from 'react-i18next'
import { useSearch } from './useSearch'

// Components
import { useQueryClient } from '@tanstack/react-query'
import EmptyState from 'components/AccordionTable/EmptyState'
import ActiveEventHeader from 'components/ActiveEventHeader'
import { FieldValue } from 'components/Modals/ManagePartyModal/types'
import Wrapper from 'components/Wrapper'
import { EventResponse } from 'misc/api/hooks/event.types'
import { queryKeyBuilders } from 'misc/api/queries'
import { Party } from 'misc/api/types'
import { EventResultSection } from './EventResultSection'

interface EventResultProps {
  deactivateEvent: () => void
  event: EventResponse
  token: string
  lead_capture_token: string
  publishEvent: () => void
  fetchEvent: (eventId: number) => void
}
const EventResult = ({
  deactivateEvent,
  event,
  token,
  lead_capture_token,
  publishEvent,
  fetchEvent
}: EventResultProps) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const [
    { parties, checkinUrl, fields },
    { updateParty, cancelParty }
  ] = useCheckIn(event)
  const [{ search }, { setSearch, filterGuests }] = useSearch()

  const updateState = (party: Party) =>
    new Promise<Party>(resolve => {
      setTimeout(() => {
        resolve(party)
      }, 150)
    }).then(party => {
      return updateParty(party)
    })

  const updateCancelledState = (party: Party) =>
    new Promise<Party>(resolve => {
      setTimeout(() => {
        resolve(party)
      }, 150)
    }).then(party => cancelParty(party))

  const toggleCancelGuest = (guestId: number, partyId: number) => {
    const party = parties.find(p => p.id === partyId)

    if (!party) {
      return
    }

    const guestIndex = party.guests.map(guest => guest.id).indexOf(guestId)

    // Handles cancel and undo state on guest
    const guest = party.guests[guestIndex]
    party.guests[guestIndex].canceled = !guest.canceled
    updateCancelledState(party)
  }

  const deleteGuest = (guestId: number, partyId: number) => {
    const party = parties.find(party => party.id === partyId)

    if (!party) {
      return
    }

    const guestIndex = party.guests.map(guest => guest.id).indexOf(guestId)

    party.guests.splice(guestIndex, 1)
    updateState(party)
  }

  const guestDetailsHaveBeenUpdated = (
    guestId: number,
    partyId: number,
    guestData: Record<string, FieldValue>
  ) => {
    const party = parties.find(party => party.id === partyId)

    if (!party) {
      return
    }

    const guestIndex = party.guests.map(guest => guest.id).indexOf(guestId)

    party.guests[guestIndex].guest_data = guestData

    queryClient.invalidateQueries({
      queryKey: queryKeyBuilders.GET_ALL_PARTIES(event?.event)
    })

    fetchEvent(event.event)
  }

  const cancelPartyHandler = (partyId: number) => {
    const party = parties.find(party => party.id === partyId)

    if (!party) {
      return
    }

    updateCancelledState({
      ...party,
      guests: party.guests.map(guest => ({
        ...guest,
        canceled: true
      }))
    })
  }

  const restorePartyHandler = (partyId: number) => {
    const party = parties.find(party => party.id === partyId)

    if (!party) {
      return
    }

    updateCancelledState({
      ...party,
      guests: party.guests.map(guest => ({
        ...guest,
        canceled: false
      }))
    })
  }

  const emptyStateMessage =
    event.state === 'DRAFT' ? (
      <Trans
        i18nKey="eventNotActive"
        values={{
          draft: t('states.DRAFT.headingActive').toUpperCase(),
          open: t('states.ACTIVE.headingDraft').toUpperCase()
        }}>
        The event is inactive, change event status from{' '}
        <span className="EventMark">draft</span> to{' '}
        <span className="EventMark">open</span> to start collecting sign-ups
      </Trans>
    ) : parties.length === 0 ? (
      t('noSignupsText')
    ) : (
      t('noMatchingGuestsText')
    )

  return (
    <div className="EventResult">
      <ActiveEventHeader
        event={event}
        token={token}
        lead_capture_token={lead_capture_token}
        checkinUrl={checkinUrl}
        deactivateEvent={deactivateEvent}
        publishEvent={publishEvent}
        handleSearchInput={(e: { target: { value: string } }) =>
          setSearch(e.target.value)
        }
        search={search}
      />
      <Wrapper className="EventResultWrapper" flex={undefined}>
        {parties.length === 0 ? (
          <EmptyState message={emptyStateMessage} />
        ) : (
          <>
            <header className="AccordionHeader">
              <h1>{t('guestlist')}</h1>
            </header>

            {event.guestlists.map((guestlist, i) => (
              <EventResultSection
                key={i}
                guestlist={guestlist}
                parties={parties}
                filterGuests={filterGuests}
                event={event}
                fields={fields}
                toggleCancelGuest={toggleCancelGuest}
                cancelPartyHandler={cancelPartyHandler}
                restorePartyHandler={restorePartyHandler}
                deleteGuest={deleteGuest}
                guestDetailsHaveBeenUpdated={guestDetailsHaveBeenUpdated}
                search={search}
              />
            ))}
          </>
        )}
      </Wrapper>
    </div>
  )
}

export default EventResult
