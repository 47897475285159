/** @jsxImportSource @emotion/react */

import Modal, { Actions, Content, Header } from '../../Modal'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'
import { Field } from 'misc/api/hooks/event.types'
import { Guest } from 'misc/api/types'
import { getConnectedFields } from '../helper'
import { FieldFactory } from './FieldFactory'
import { FieldValue, FieldWithValue } from '../types'
import { FieldType } from 'misc/field.types'
import { css } from '@emotion/react'
import { useEffect, useRef, useState } from 'react'
import useUpdateGuestDetails from './useUpdateGuestDetails/useUpdateGuestDetails'
import Spinner from './Spinner'
import { ValidationError } from './useUpdateGuestDetails/useUpdateGuestDetails.types'

const getFieldDefaultValue = (field: FieldWithValue) => {
  if (
    field.required &&
    field.type === FieldType.BOOL &&
    (field.value === null || field.value === undefined)
  ) {
    // to avoid BE validation error
    // {
    //   "validation_errors": {
    //     "checkbox__b4e6b5da-22dd-4ea2-b373-1898fe76f18d": {
    //       "error": "Value of the required key can not be empty",
    //       "guest_data_key": "checkbox__b4e6b5da-22dd-4ea2-b373-1898fe76f18d",
    //       "invalid_data": null
    //     }
    //   }
    // }
    field.value = false
  }

  return field
}

const getHeaderTitle = (fields: FieldWithValue[]) => {
  const firstNameField = fields.find(
    field => field.type === FieldType.FIRST_NAME
  )
  const lastNameField = fields.find(field => field.type === FieldType.LAST_NAME)

  let title = ''
  if (firstNameField) {
    title += firstNameField.value
  }
  if (lastNameField) {
    title += ` ${lastNameField.value}`
  }

  return title.trim()
}

interface EditGuestDetailsModalProps {
  handleCloseModal: () => void
  onChangesSaved: (guestData: Record<string, FieldValue>) => void
  fields: Field[]
  isLeader: boolean
  guest: Guest
}
export const EditGuestDetailsModal = ({
  handleCloseModal,
  fields,
  isLeader,
  guest,
  onChangesSaved
}: EditGuestDetailsModalProps) => {
  const { t } = useTranslation()

  const dataFields = getConnectedFields(guest, fields)

  const [formValues, setFormValues] = useState<FieldWithValue[]>(dataFields)
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState<(string | null)[]>(
    dataFields.map(() => null)
  )
  const hasAnyErrors = errors.some(error => error)
  const [
    errorMessageUnrelatedToValidation,
    setErrorMessageUnrelatedToValidation
  ] = useState<string | null>(null)
  const { mutation: updateGuestDetailsMutation } = useUpdateGuestDetails()

  const isSaveDisabled =
    !isDirty || hasAnyErrors || updateGuestDetailsMutation.isPending

  const handleSave = async () => {
    const guestId = guest.id

    const guestData = Object.fromEntries(
      formValues
        .map(field => {
          return getFieldDefaultValue(field)
        })
        .filter(field => field.value !== null && field.value !== undefined)
        .map(field => [field.key, field.value])
    )

    try {
      await updateGuestDetailsMutation.mutateAsync({
        guestId,
        guest_data: guestData
      })
    } catch (error) {
      if (error instanceof ValidationError) {
        const validationErrors = error.validationErrors

        setErrors(prev =>
          prev.map((prevError, prevIndex) => {
            const errorEntry =
              validationErrors[Object.keys(validationErrors)[0]]

            return errorEntry.guest_data_key === formValues[prevIndex].key
              ? JSON.stringify(errorEntry.error)
              : prevError
          })
        )
      } else {
        setErrorMessageUnrelatedToValidation('Something went wrong')
      }

      return
    }

    onChangesSaved(guestData)
    handleCloseModal()
  }

  const errorMessageRef = useRef<HTMLParagraphElement | null>(null)

  useEffect(() => {
    if (updateGuestDetailsMutation.isError) {
      // Wait for the DOM update to complete
      requestAnimationFrame(() => {
        if (errorMessageRef.current) {
          errorMessageRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
          })
        }
      })
    }
  }, [updateGuestDetailsMutation.isError])

  return (
    <Modal size="medium">
      <div
        css={css`
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          overflow-y: hidden;
        `}>
        <Header
          customCss={css`
            border-bottom: 1px solid #ebeef2;
          `}>
          <span>{getHeaderTitle(dataFields)}</span>
          {isLeader && (
            <span
              css={css`
                font-weight: 400;
              `}>
              {/* empty space char */}
              {' - '}
              MAIN GUEST
            </span>
          )}
        </Header>
        <Content
          customCss={css`
            overflow-y: auto;
            flex: 1;
            padding-top: 16px !important;
            padding-bottom: 16px !important;
          `}>
          {formValues.map((field, index) => {
            return (
              <FieldFactory
                key={index}
                field={field}
                onChange={(value, error) => {
                  setIsDirty(true)
                  setErrors(prev => {
                    return prev.map((prevError, prevIndex) =>
                      prevIndex === index ? error : prevError
                    )
                  })

                  setFormValues(prev => {
                    return prev.map((prevField, prevIndex) =>
                      prevIndex === index ? { ...prevField, value } : prevField
                    )
                  })
                }}
                error={errors[index]}
              />
            )
          })}
          {errorMessageUnrelatedToValidation && (
            <p
              css={css`
                display: flex;
                padding: 16px;
                align-items: center;
                gap: 10px;
                align-self: stretch;
                border-radius: 4px;
                border: 1px solid #fe2f1f;
                background: rgba(254, 47, 31, 0.2);
                margin-bottom: 16px;
              `}>
              {errorMessageUnrelatedToValidation}
            </p>
          )}
          <div
            ref={errorMessageRef}
            css={css`
              height: 50px;
            `}
          />
        </Content>
        <Actions
          leftActions={
            <Button type="secondary" onClick={handleCloseModal}>
              {t('cancel')}
            </Button>
          }
          rightActions={
            <Button
              type="primary"
              onClick={handleSave}
              disabled={isSaveDisabled}>
              {updateGuestDetailsMutation.isPending ? (
                <Spinner size={16} />
              ) : (
                t('save')
              )}
            </Button>
          }
        />
      </div>
    </Modal>
  )
}
