import { Party } from 'misc/api/types'
import React from 'react'
import Accordion, { Content } from './Accordion'
import {
  Guest,
  PartyCheckinStatus,
  NumberOfGuests,
  GuestTitle
} from './Accordion/Guest'
import { PartyActions, GuestActions } from './Accordion/Guest/Actions'
import { IconCheckmark } from './Accordion/Guest/icons'
import { EventResponse, Field } from 'misc/api/hooks/event.types'
import moment from 'moment'
import { FieldValue } from 'components/Modals/ManagePartyModal/types'
import { filterGuests } from './helper'

const formatAddedDate = (timestamp: string) =>
  moment.parseZone(timestamp).format('YYYY-MM-DD, HH:mm')

interface AccordionProps {
  party: Party
  index: number
  event: EventResponse
  cancelParty: (partyId: number) => void
  restoreParty: (partyId: number) => void
  deleteGuest: (guestId: number, partyId: number) => void
  toggleCancelGuest: (guestId: number, partyId: number) => void
  updateGuestDetails: (
    guestId: number,
    partyId: number,
    guestData: Record<string, FieldValue>
  ) => void
  accordionOpen: number | null
  sortedFollowerFields: Field[]
  sortedLeaderFields: Field[]
  leaderFields: Field[]
  followerFields: Field[]
  handleAccordionClick: (index: number) => void
}
const AccordionRow = ({
  party,
  index,
  event,
  cancelParty,
  restoreParty,
  deleteGuest,
  toggleCancelGuest,
  updateGuestDetails,
  accordionOpen,
  sortedFollowerFields,
  sortedLeaderFields,
  leaderFields,
  followerFields,
  handleAccordionClick
}: AccordionProps) => {
  // Party specific information
  const isActive = accordionOpen === index
  const leader = party.guests.find(({ guest_type }) => guest_type === 'leader')
  const guests = filterGuests(party.guests, 'follower')
  const partyIsCanceled = party.guests.every(guest => guest.canceled)

  if (!leader) {
    return null
  }

  return (
    <Accordion key={index} isActive={isActive}>
      <Guest
        data={leader.guest_data}
        filteredFields={sortedLeaderFields}
        isCanceled={partyIsCanceled}
        handleAccordionClick={handleAccordionClick}
        accordionId={index}>
        <div className="date">{formatAddedDate(party.added)}</div>
        <PartyCheckinStatus party={party} />
        {guests.length > 0 && (
          <NumberOfGuests
            guests={party.guests.filter(guest => !guest.canceled).length}
          />
        )}
      </Guest>

      {isActive && (
        <Content>
          <PartyActions
            updateGuestDetails={updateGuestDetails}
            handleCancelParty={cancelParty}
            handleRestoreParty={restoreParty}
            partyId={party.id}
            party={guests}
            leader={leader}
            leaderFields={leaderFields}
            followerFields={followerFields}
            canceled={partyIsCanceled}
            canRestore={
              !event.guest_max_limit ||
              (event.state !== 'FULL' &&
                event.nr_of_guests + guests.length <= event.guest_max_limit)
            }
          />
          <Guest
            isCanceled={leader.canceled}
            data={leader.guest_data}
            filteredFields={sortedLeaderFields}
            handleAccordionClick={undefined}
            accordionId={undefined}>
            <div className="date">{formatAddedDate(leader.added)}</div>
            {leader.checked_in && <IconCheckmark handleClick={undefined} />}
            <GuestActions
              guest={leader}
              guestId={leader.id}
              partyId={party.id}
              eventFull={event.state === 'FULL'}
              handleToggleGuestCancel={toggleCancelGuest}
              handleDeleteGuest={deleteGuest}
              leader={false}
            />
          </Guest>

          {guests.length > 0 && <GuestTitle fields={sortedFollowerFields} />}

          {guests &&
            guests.map((guest, index) => (
              <Guest
                isCanceled={guest.canceled}
                data={guest.guest_data}
                filteredFields={sortedFollowerFields}
                key={index}
                handleAccordionClick={undefined}
                accordionId={undefined}>
                <div className="date">{formatAddedDate(guest.added)}</div>
                {guest.checked_in && <IconCheckmark handleClick={undefined} />}
                <GuestActions
                  guest={guest}
                  guestId={guest.id}
                  partyId={party.id}
                  eventFull={event.state === 'FULL'}
                  handleToggleGuestCancel={toggleCancelGuest}
                  handleDeleteGuest={deleteGuest}
                  leader={false}
                />
              </Guest>
            ))}
        </Content>
      )}
    </Accordion>
  )
}

export default AccordionRow
